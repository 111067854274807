/* ==========================================================================
    header-anim-1
    ========================================================================== */

// ==== Variables hauteurs header

$height-header-anim-1-init: 10rem; // hauteur initial
$height-header-anim-1-affix: 5rem; // hauteur affix
$height-navbar-ecommerce: 0; // si pas de navbar e-commerce
// $height-navbar-ecommerce: 2rem; // si navbar e-commerce activee

// ==== Global

.header-anim-1 {
	position: relative;
	top: $height-navbar-ecommerce;
	height: $navbar-height;
	background-color: $navbar-default-bg;
	z-index: 1000;

	.navbar {
		border: 0;
	}

	@media(min-width: $grid-float-breakpoint) {
		height: $height-header-anim-1-init;
	}
}

// ==== Container logo

.header-anim-1__logo {
	position: absolute;
	z-index: 1100;
	top: 0;
	left: 0;
	max-width: 50%;
	height: 100%;
	padding: .5rem;
	background: white;
	border-right: solid .5rem $brand-second;

	@media(min-width: $grid-float-breakpoint) {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 15rem;
		height: $height-header-anim-1-init;
		transition: height ease-out .5s;
		transform-origin: center;
	}
}

// ==== Hauteur du logo

.header-anim-1__img-logo--animated {
	display: block;
	max-height: 100%;

	@media(min-width: $grid-float-breakpoint) {
		width: 100%;
		object-fit: contain;
		height: $height-header-anim-1-init;
		transition: height ease .5s;
	}
}

// ==== Hauteur du block navbar

.header-anim-1__nav {
	width: 100%;
	height: $navbar-height;

	@media(min-width: $grid-float-breakpoint) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: $height-header-anim-1-init;
		transition: height ease .5s;
	}
}

// ==== Animations (affix)

.header-anim-1__inner {
	position: relative;
	left: 0;
	right: 0;
	background-color: $navbar-default-bg;

	&.affix {
		position: relative;
	}
}

.banane {
	overflow: hidden;
	min-height: 2rem;
	transition: opacity ease .5s, height ease .2s;
	opacity: 1;
}


@media(min-width: $grid-float-breakpoint) {

	.header-anim-1 {
		position: fixed;
		top: $height-navbar-ecommerce;
		height: $navbar-height;
		background-color: transparent;
		z-index: 1000;
	
		@media(min-width: $grid-float-breakpoint) {
			height: $height-header-anim-1-init;
		}
	}

	.header-anim-1__inner {
		position: fixed;
		height: $height-header-anim-1-init;
		top: $height-navbar-ecommerce;
		transition: height ease .2s, background-color ease .2s;

		.navbar-default {
			background-color: transparent;
			border-color: transparent;

			.navbar-nav>li>a {
				color: white;
				transition: color ease .2s;

				&:hover,
				&:focus,
				&:active {
					color: $brand-second;
				}
			}

			.navbar-nav>li.active>a {
				color: lighten($brand-second, 15%);

				&:hover {
					color: $brand-tierce;
				}
			}
		}

		&.affix {

			.banane {
				height: 0;
				transition: opacity ease .5s, height ease .2s;
				opacity: 0;
			}

			position: fixed;
			height: $height-header-anim-1-affix;
			top: $height-navbar-ecommerce;
			// background-color: white;
			background: linear-gradient(rgba($text-color, .75), rgba($text-color, 0))!important;
			.navbar-default {
				background-color: transparent;
				border-color: transparent;

				.navbar-nav>li>a {
					color: white;

					&:hover {
						color: $navbar-default-link-hover-color;
					}
				}

				.navbar-nav>li.active>a {
					color: $brand-primary;

					&:hover {
						color: $navbar-default-link-hover-color;
					}
				}
			}

			.header-anim-1__nav {
				height: $height-header-anim-1-affix;
			}

			.header-anim-1__logo {
				height: $height-header-anim-1-affix;
			}

			.header-anim-1__img-logo--animated {
				height: 3.5rem;
			}
		}
	}
}
