/* ==========================================================================
Accueil
========================================================================== */
.link-accueil {
  @extend .texte-blanc;
  @extend .text-center;
  transition: all ease .2s;
  &:hover {
    transition: all ease .2s;
    color: lighten($brand-second, 15%) !important;
  }
}


.header-accueil {
@extend .bg-footer;
@extend .animated;
@extend .fadeInDown;
@extend .navbar-centered;
min-height: 5vh;
position: relative;
z-index: 600;

@media(min-width: $grid-float-breakpoint) {
.nav,.navbar-nav>li>a {
color: white !important;
}
}

}

.logo-accueil {
@extend .img-responsive;
@extend .center-block;
@extend .animated;
@extend .fadeInUp;
animation-delay: .5s;
position: relative;
z-index: 10;
// >img {
// @extend .img-responsive;
// display: inline-block;
// max-height: 15rem;
// }
}

.main-accueil {
min-height: 30vh;
background-color: transparent;
box-shadow: 0px 0px 0px transparent;
}

.btn-accueil {
@extend .animated;
@extend .fadeInDown;
@extend .btn;
@extend .text-center;
@extend .btn-primary;
animation-delay: .4s;
text-transform: uppercase;
box-shadow: 0px 5px 10px rgba($text-color, .25);
font-weight: 700;
margin-top: -1rem;
border-top:none;
border-bottom: solid .2rem white !important;
border-right: solid .2rem white !important;
border-left: solid .2rem white !important;
border-radius: 0 0 3rem 3rem !important;
transition: all ease-in-out .1s;
background: linear-gradient(darken($brand-second, 5%), lighten($brand-second, 5%));;
color: white;
position: relative;
z-index: 200;

&:hover {
padding-top: 2rem;
margin-bottom: -.9rem;
background: linear-gradient(darken($brand-primary, 5%), lighten($brand-primary, 5%));
//  color: $brand-second;
}

}

.footer-accueil {
@extend .footer;
@extend .text-center;
padding: 5%;
background-color: rgba($body-bg, .8);
overflow: hidden;

h1 {
font-size: $font-size-h2;
}
}

.col-footer-accueil-left {
@extend .col-sm-6;
@extend .text-left;

@media (max-width: $screen-xs-max) {
text-align: center;
}
}

.col-footer-accueil-right {
@extend .col-sm-6;
@extend .text-right;

@media (max-width: $screen-xs-max) {
text-align: center;
padding-top: 20px;
}
}


/* ==========================================================================
Accueil
========================================================================== */

.parallax-window {
align-items: center;
min-height: 30vh;
overflow: hidden;
}


.parallax-window-accueil {
@extend .parallax-window;
border-top: solid .3rem $brand-second;
border-bottom: solid .3rem $brand-tierce;
display: flex;
flex-flow: row wrap;
justify-content: center;
text-align: center;
min-height: 90vh;
background: linear-gradient(to bottom, rgba(black, 0) 0%, rgba(black, .6) 100%);

>a {
  @extend .superside;
display: inline-block;
position: relative;
font-size: 4rem;
text-decoration: none;
color: white;


>img {
@extend .img-responsive;
max-width: 90%;
margin: 0 auto;
transition: transform ease-out .3s;
}

>span {
display: inline-block;
transition: letter-spacing ease-out .3s;
letter-spacing: -.1rem;
}

@media(min-width: $screen-sm-min) {
font-size: 6.5rem;

&:hover {
opacity: 1;

>img {
transform: scale(1.1);
}

>span {
letter-spacing: .1rem;
}
}
}

@media(min-width: $screen-md-min) {
font-size: 6rem;
}
}
}


.bloccueil {
margin: 10vh auto;
max-width: 60rem;
overflow: hidden;
position: relative;
z-index: 100;
@extend .animated;
@extend .fadeInDown;
@extend .container;
background: rgba($body-bg, .75);
background: white;
padding: 0rem;

.content-bloccueil {
display: flex;
flex-direction: column;

@media(min-width: $screen-md-min) {
flex-direction: row;
}
}

.side-bloccueil-left {
padding: 1.7rem;
@extend .text-center;
width: 100%;

@media(min-width: $screen-md-min) {
width: 65%;
}
}

.side-bloccueil-right {
overflow: hidden;
margin: 0;
padding: 0;
width: 100%;

@media(min-width: $screen-md-min) {
width: 35%;
}

>img {
@extend .center-block;
@extend .img-responsive;
width: 40%;
@media(min-width: $screen-md-min) {
width: 100%;
}
}
}
}


/* ==========================================================================
Footer Accueil
========================================================================== */

.footer-accueil {
@extend .footer;
@extend .text-center;
padding: 5% 0;
h1 {
font-size: $font-size-large;
}
}

.col-footer-accueil-left {
@extend .col-sm-6;
@extend .text-left;
@media (max-width: $screen-xs-max) {
text-align: center;
}
}

.col-footer-accueil-right {
@extend .col-sm-6;
@extend .text-right;
@media (max-width: $screen-xs-max) {
text-align: center;
padding-top: 20px;
}
}

.img-intro {
@extend .animated;
@extend .fadeInUp;
@extend .img-responsive;
@extend .center-block;
position: relative;
z-index: 300;
animation-delay: .2s;
margin: 0rem auto;
}
