//== Thumbnails
//
//##

//** Padding around the thumbnail image
$thumbnail-padding:           0px !default;
// $thumbnail-padding:           0 !default;
//** Thumbnail background color
$thumbnail-bg:                white !default;
//** Thumbnail border color
$thumbnail-border:            transparent !default;
//** Thumbnail border radius
$thumbnail-border-radius:     0 !default;

//** Custom text color for thumbnail captions
$thumbnail-caption-color:     $text-color !default;
//** Padding around the thumbnail caption
// $thumbnail-caption-padding:   9px !default;
$thumbnail-caption-padding:   1rem !default;
